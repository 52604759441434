import React, { Component, createContext } from 'react';
import axios from 'axios';

export const MapContext = createContext();

export default class ContextProvider extends Component {
    static Consumer = MapContext.Consumer;

    state = {
        currentLang: window.lang || 'ua',
        locale: null,
        addFind: new URL(window.location.href).searchParams.get("type") === "found",
        addLost: new URL(window.location.href).searchParams.get("type") === "lost",
        addressMarkersArr: [],
        center: {
            lat: 50.45466,
            lng: 30.5238,
        },
        zoom: 6,
    };

    getLocaleFromBase = () => {
        axios
            .get(`https://www.luckfind.me/api/v1/locales/?lang=${this.state.currentLang}`)
            .then(response => {
                if (response.data) {
                    this.setState({
                        locale: JSON.parse(response.data)
                    });
                } else {
                    throw new Error("Locales not found");
                }
            })
    };

    getMarkersFromBase = () => {
        axios.get(`https://www.luckfind.me/api/v1/coordinates/`)
            .then(result => {
                let i = 1;
                let minX, minY, maxX, maxY, zoom;
                if (!!result.data.data.length) {
                    let newArr = result.data.data.map(el => {

                            let coordinates = el.coordinates.split(", ")
                            if (maxX === undefined || coordinates[1] > maxX) {
                                maxX = +coordinates[1]
                            }
                            if (minX === undefined || coordinates[1] < minX) {
                                minX = +coordinates[1]
                            }
                            if (maxY === undefined || coordinates[0] > maxY) {
                                maxY = +coordinates[0]
                            }
                            if (minY === undefined || coordinates[0] < minY) {
                                minY = +coordinates[0]
                            }
                            let obj = {coordinates: el, id: i + '123frontAddress'}

                            i = ++i
                            return obj
                        }
                    );

                    let x = Number((minX + maxX) / 2)
                    let y = Number((minY + maxY) / 2)
                    if (maxX - minX < 24 || maxY - minY < 5) {
                        zoom = 6
                    } else if (maxX - minX < 12 && maxY - minY < 2.5) {
                        zoom = 7
                    } else if (maxX - minX < 6 && maxY - minY < 1) {
                        zoom = 8
                    } else if (maxX - minX < 3 && maxY - minY < .5) {
                        zoom = 9
                    } else if (maxX - minX < 1.5 && maxY - minY < .25) {
                        zoom = 10
                    } else if (maxX - minX < 0.75 && maxY - minY < .125) {
                        zoom = 11
                    } else if (maxX - minX < 0.325 && maxY - minY < .065) {
                        zoom = 12
                    }

                    this.setState({
                        addressMarkersArr: [...newArr],
                        center: {lat: y, lng: x},
                        zoom: zoom || this.state.zoom,
                    })
                } else {
                    this.setState({
                        addressMarkersArr: []
                    })
                }
            })
    }

    changeMapParam = (lat, lng, zoom) => {

        this.setState({
            center: {lat: lat, lng: lng},
            zoom: zoom
        })
    }

    render() {
        return (
            < MapContext.Provider
                value={
                    {
                        map: {
                            addressMarkersArr: this.state.addressMarkersArr,
                            center: this.state.center,
                            zoom: this.state.zoom,
                            locale: this.state.locale,
                            frontOfMapClose: this.state.frontOfMapClose,
                    },
                        getMarkersFromBase: this.getMarkersFromBase,
                        changeStateToFalse: this.changeStateToFalse,
                        changeMapParam: this.changeMapParam,
                        getLocaleFromBase: this.getLocaleFromBase,
                }
                }>
    {
        this.props.children
    }
    </MapContext.Provider>
    )
        ;
    }
}



