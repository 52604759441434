import React, {Component} from 'react'

export default class DarkFront extends Component {

    state = {
        frontOfMapClose: true,
    }

    changeStateToFalse = (stateName) => {
        this.setState({
            [stateName]: false
        })
    }

    render() {
        return (
            <div className={this.state.frontOfMapClose ? "frontOfMap" : "frontOfMapClose"}
                 onClick={() => this.changeStateToFalse('frontOfMapClose')}>
                {/*<div className="frontOfMapButton">Показати карту</div>*/}
            </div>
        )
    }
}