import React, { Component } from 'react';

import Map from './Map/Map';
import DarkFront from './DarkFront/DarkFront';
import {MapContext} from './Context';

import './App.css';

class App extends Component {
    static contextType = MapContext;

    componentDidMount() {
        this.context.getMarkersFromBase();
    }

    render() {
        return (
            <div className="App">
                <DarkFront/>
                <Map/>
            </div>
        )
    }
}

export default App;
