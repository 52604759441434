import React, {Component} from 'react'
import {Map, TileLayer, Marker, Popup, ZoomControl} from 'react-leaflet';
import MarkerClusterGroup from '../dist/react-leaflet-markercluster.js';
import {MapContext} from '../Context';
import GeoSearch from '../GeoSearch/GeoSearch'


export default class Somemap extends Component {
    static contextType = MapContext;

    ADVERT_API_URL = 'https://www.luckfind.me/ua/advert.php?id=';

    componentDidMount() {
        this.context.getLocaleFromBase()
    }

    gridMarkers = () => {
        let key = 1
        let locale = this.context.map.locale

        return <MarkerClusterGroup showCoverageOnHover={false}>
            {!!this.context.map.addressMarkersArr.length && this.context.map.addressMarkersArr.map(obj =>
                <Marker
                    key={++key + 100 + 'marker'}
                    position={obj.coordinates.coordinates.split(', ')}>
                    <Popup minWidth={90}>
                        <div className={obj.coordinates.type === 'found' ? 'markerGreenColor' : 'markerOrangeColor'}>
                            <p>
                                {`${obj.coordinates.type === 'found' ? locale.advertRibbonFind : locale.advertRibbonLost} `}
                            </p>
                            <span>
                                {<a className={obj.coordinates.type === 'found' ? 'markerGreenColor' : 'markerOrangeColor'}
                                    href={this.ADVERT_API_URL + obj.coordinates.id}
                                    target="_blank"
                                    rel="noopener noreferrer">{obj.coordinates.user_item || obj.coordinates.item}</a>}
                            </span>
                        </div>
                    </Popup>
                </Marker>)}
        </MarkerClusterGroup>
    }


    render() {
        return (
            <div className='leaflet-wrapperMap'>
                <div className="leafletBox">
                    <Map center={[this.context.map.center.lat, this.context.map.center.lng]}
                         zoom={this.context.map.zoom}
                         zoomControl={false}
                         maxZoom={17}
                         width={100}>
                        <TileLayer
                            attribution='&amp;copy <a href=" http://osm.org/copyright">OpenStreetMap</a> contributors'
                            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                        />
                        {this.gridMarkers()}
                        <GeoSearch/>
                        <ZoomControl position="topright"/>
                    </Map>
                </div>
            </div>
        )
    }
}
